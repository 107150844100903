#routeDetourReport {
    box-sizing: border-box;
    height: 100%;
    padding: 10px;
    width: 100%;
};

#routeDetourReport .leaflet-container{
    border: 2px solid #000;
    .leaflet-popup-content-wrapper{
        min-width: 200px;
        border-radius: 0px;
    }
};

.route-detour-popup .leaflet-popup-content{
    border-radius: 0;
}

.route-detour-popup .title{}

.route-detour-popup .content .popup-table{
    & tr td{
        border: 1px solid silver;
        padding: 10px;
    }
}