@import '../../Base/settings.scss';

.marker-info__extra-content__image-wrapper {
    height: 215px;
    width: 100%;
    background-color: $navbar-background2;
    position: relative;
    overflow: hidden;
}

.marker-info__extra-content__driver {
    padding: $grid-unit * 3;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.marker-info__driver__image-wrapper {
    width: 120px;
    height: 120px;
    background-color: $navbar-background2;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.marker-info__driver__info {
    padding-left: $grid-unit * 2;
}

.marker-info__driver__label {
    font-weight: bolder;
}
