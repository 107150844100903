/* stylelint-disable */
@import "../Base/settings.scss";
@import "../../Theme.scss";

$icon-size: 50px;

.marker-popup--navigation {
  .leaflet-popup-content {
    margin: 0;
  }

  .leaflet-popup-content-wrapper {
    background: none;
  }

  .marker-popup__row {
    display: flex;

    &:not(:last-child) {
      border-bottom: 2px solid $expandable-bg-1;
    }
  }

  .marker-popup__row--dark {
    background-color: $dark-marker-popup-title-background-color;
  }

  .marker-popup__row__item {
    color: $dark-marker-popup-text-color !important;
    display: flex;
    flex: 1 0 auto;
    width: 50%;

    &:not(:last-child) {
      border-right: 1px solid $navbar-text-color;
    }

    svg {
      align-self: flex-start;
    }
  }

  .marker-popup__row__item--3 {
    .marker-popup__row__item {
      width: 33.33%;
    }
  }

  .marker-popup__row__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .marker-popup__content--extended {
    background-color: $expandable-bg-3;
    margin-bottom: 0px;
    border-radius: 8px 8px 0 0;

    .marker-popup__row {
      display: flex;
      border-radius: $grid-unit;
      overflow: hidden;
    }
  }

  .marker-popup__content-icon {
    flex: 0 0 auto;
    width: $icon-size;
    height: $icon-size;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $navbar-text-color;
    border: none;
    cursor: pointer;
    padding: $grid-unit * 2;

    &:focus {
      outline: none;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    @each $status in $status-list {
      $i: index($status-list, $status);

      &--#{$status} {
        background-color: nth($status-list-badge, $i);

        path {
          fill: nth($status-list-bg, $i);
        }
      }
    }
  }

  .marker-popup__content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $navbar-menu-options-text;
    font-size: 16px;
    line-height: 21px;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;

    &:not(:last-child) {
      border-right: 1px solid $navbar-text-color;
      min-width: 136px;
    }
  }

  .marker-popup__content-grow {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .marker-popup__content-justify-right {
    justify-content: right;
  }
  .marker-popup__content-text__title {
    font-size: 11px;
    line-height: 15px;
    text-transform: capitalize;
  }

  .marker-popup__content-text__info {
    white-space: break-spaces;
    color: $app-base-text-color;
  }

  .marker-popup__driver-icon {
    background: #ffffff;
    border-radius: 50%;
    fill: $success-badge;
  }

  .marker-popup__fgps-size {
    font-size: small;
  }
}

@keyframes slideUpPanel {
  0% {
    max-height: 0;
    opacity: 0;
  }

  99% {
    max-height: 250px;
    overflow: hidden;
    opacity: 1;
  }

  100% {
    max-height: 250px;
    overflow: visible;
    opacity: 1;
  }
}

.marker-popup--navigation-open {
  z-index: 2;

  .leaflet-popup-content {
    width: 100% !important;
  }

  .marker-popup__content--extended {
    overflow: hidden;
    animation: slideUpPanel 240ms ease-in-out;
    width: 400px;
  }
}

.leaflet-popup-content {
  width: 100% !important;
}

.marker-popup__content--extended {
  background-color: $dark-marker-popup-content-background-color !important;
  overflow: hidden;
  animation: slideUpPanel 240ms ease-in-out;
  width: 400px;
}

.navigation-popup-dir {
  flex-direction: column;
  justify-content: center;
  color: #a0a8c5;
  font-size: 11px;
  line-height: 15px;
  text-decoration: none;
  white-space: nowrap;
}

.generic-popup-veinte {
  width: 20% !important;
}

.generic-popup-treinta {
  width: 30% !important;
}
