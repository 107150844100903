.forbContainer {
  color: #fff;
  height: 100vh;
  position: relative;
  width: 100%;
}

.forbVAlign {
  left: 50%;
  margin: 0;
  position: absolute;
  text-align: center;
  top: 40%;
  transform: translateY(-40%);
  transform: translateX(-50%);
  transform: translateY(-40%);
  transform: translateX(-50%);
}

.forbMsg {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
}