@import "../../../Theme";

/* stylelint-disable */

.MuiFormGroup-root {
  margin-top: 10px;
}

.slidingPaneRight .slide-pane__header {
  background-color: #1e1e2f;
}

.slidingPaneRight .slide-pane__title {
  color: whitesmoke
}

.slidingPaneRight .slide-pane__content {
  background-color: #27293d;
}

.slidingPaneRight .slide-pane__close {
  color:whitesmoke
}

.formLabelFormik {
  color:whitesmoke
}

.footerSlidePanel {
  position: absolute;
  bottom: 10px;
}

.MuiFormControlLabel-label {
  color: $input-label;
}

.formikSelect {
  height: calc(1.5rem + .75rem + 2px);
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.confEventButtonContainer {
  margin-bottom: 15px;
}

.editorConfEvento {
  width: 60%;
  padding: 15px;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  margin: 15px 0 15px 0;
}

.MuiFilledInput-marginDense {
  height: 37px;
  padding-bottom: 4px;
}

.button-container{
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.button-container button{
  margin-left: 10px;
}