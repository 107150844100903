@import "../../../Theme";

/* stylelint-disable */
.listContainer {
  padding: 10px 20px 10px 20px;
}

.ReactModalPortal {
  position: relative;
  z-index: 1300;
}

.slidingPaneRight .slide-pane__header {
  background-color: #1e1e2f;
}

.slidingPaneRight .slide-pane__title {
  color: $slide-panel-header-text-color!important;
}

.slidingPaneRight .slide-pane__content {
  background-color: #27293d;
  color: black!important;
}

.slidingPaneRight .slide-pane__close {
  color: whitesmoke;
}

.formLabelFormik {
  color: $slide-panel-content-text-color!important;
}

.footerSlidePanel {
  position: absolute;
  bottom: 10px;
}

.actionIcon {
  padding: 0 !important;
}

.text-input {
  background-color: rgba(47, 47, 47, 1) !important;
  color: #858585 !important;
  border: none;
}

.historyADContainer {
  margin-top: 20px;
}
