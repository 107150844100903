/* stylelint-disable */
@import "./Scrollbars";
@import "./Theme";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  //background-image: url('/assets/img/background.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}

/*.leaflet-control-layers-overlays {
    background-image: url("/assets/img/kms.png");
    background-size: cover;
    background-repeat: no-repeat;
}*/

.text-color--white {
  color: #ffffff;
}

.flex {
  display: flex;
  width: 100%;

  &.column {
    flex-direction: column;
  }

  &.between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }
}

.pad-1 {
  padding: 4px;
}

.pad-2 {
  padding: 8px;
}

.pad-3 {
  padding: 12px;
}

.pad-4 {
  padding: 16px;
}

.pad-hor-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.pad-hor-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.pad-hor-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.pad-hor-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.pad-hor-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.pad-hor-5 {
  padding-left: 20px;
  padding-right: 20px;
}

.a11y-hidden {
  position: absolute !important;
  left: -99999999px !important;
}

.pad-vert-0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.pad-vert-1 {
  padding-bottom: 4px;
  padding-top: 4px;
}

.pad-vert-2 {
  padding-bottom: 8px;
  padding-top: 8px;
}

.pad-vert-3 {
  padding-bottom: 12px;
  padding-top: 12px;
}

.pad-vert-4 {
  padding-bottom: 16px;
  padding-top: 16px;
}

.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;

  transform: translate(-50%, -50%);
}

input.error {
  border-color: $red;
}

.input-feedback {
  color: $red;
  font-weight: 600;
}

.MuiButton-containedPrimary {
  background-color: $btn-primary-background-color !important;
  color: $btn-primary-text-color !important;
}

.slide-pane__content {
  background-color: $slide-panel-content-background-color !important;
}

.slide-pane__header {
  background-color: $slide-panel-header-background-color !important;
}

button:focus {
  outline: none !important;
}

.MuiDialogContent-root {
  background-color: $modal-content-background-color !important;
  color: $modal-content-text-color !important;

  .MuiFormLabel-root {
    color: $modal-content-text-color !important;
  }
}

.MuiRadio-root {
  color: $radio-button-selected !important;
}

.MuiCheckbox-root {
  color: $checkbox-selected !important;
}

.form-control,
.form-control:focus {
  color: $app-base-text-color !important;
  background-color: $input-base-background-color !important;
  border: 1px solid $input-base-border !important;
  box-shadow: none !important;
}
.form-control:focus {
  border: 1px solid $input-highlight;
}

.text-input {
  color: $app-base-text-color !important;
  border: 1px solid $input-base-border !important;
  &::placeholder {
    color: $gray-500 !important;
  }
}

// vizion.css overrides
.MuiAppBar-root.navbar--lateral .navbar__icon-button svg path:last-child {
  fill: $lateral-navbar-icons-color;
}

.expandable-panel__info-block--dark {
  background-color: $expandable-panel-subtitle-background-color;
  color: $expandable-panel-subtitle-text-color;
}

.react-bootstrap-table-page-btns-ul li .page-link,
.react-bootstrap-table-page-btns-ul li .page-link:hover {
  border: 0 !important;
}

.react-bootstrap-table-page-btns-ul li .page-link {
  margin: 0px 3px;
  border-radius: 5px;
  padding: 5px;
  color: $remote-table-pagination-unactive-link-text-color;
  background-color: $remote-table-pagination-unactive-link-background-color;
}

.react-bootstrap-table-page-btns-ul li.active .page-link {
  color: $remote-table-pagination-active-text-color;
  background-color: $remote-table-pagination-active-background-color;
}

.MuiAutocomplete-popper > * {
  background-color: $input-popper-background-color !important;
  color: $input-popper-text-color !important;
}

// .MuiSvgIcon-root{
//   color: $app-base-contrast;
// }

#manage-navbar-brand-logo,
#navbar-top-brand-logo,
#login-brand-logo {
  max-width: 400px;
  max-height: 50px;
  overflow: hidden;
}

.SearchIconColor {
  color: $brand;
}
