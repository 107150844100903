@import './settings.scss';

$navbar-size: 64px;
$navbar-size-lateral: 50px;
$navbar-title-height: 130px;
$navbar-title-width: 430px;

.MuiAppBar-root {
    &.manage-navbar--top {
        width: 100%;
        height: $navbar-size;
        background-color: $navbar-background;
        color: $navbar-foreground;
        z-index: 500;
        display: flex;
        flex-direction: row;
    }

    .manage-navbar__title {
        width: $navbar-title-width;
        background-color: $brand-background;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: white;
            text-decoration: none;
            font-size: 0;
            line-height: 0;
        }
    }

    .manage-navbar__content {
        background-color: $navbar-background;
        max-height: $navbar-size;
        display: flex;
        justify-content: space-between;
        flex: 1 1 auto;
        padding-right: 0;
    }

    .manage-navbar__content-menu {
        flex: 1 1 auto;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;
        height: 100%;
    }

    .manage-navbar__content-menu-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 10px;

        color: white;
        text-decoration: none;

        &:focus,
        &:hover,
        &:visited {
            color: white;
            text-decoration: none;
        }
    }
}
